import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {ISpecialOfferSection} from "../templates/special-offer/special-offer.interface";
import TextFormatter from "../../utils/TextFormatter";
import CustomLink from "../shared/custom-link/CustomLink";

interface ISpecialOfferProps {
  specialOfferOwner: ISpecialOfferSection,
}

const SpecialOfferOwner = ({ specialOfferOwner }: ISpecialOfferProps) => {
  const description = TextFormatter.concatOrphans(specialOfferOwner.description.data.description) || specialOfferOwner.description.data.description;
  const title = TextFormatter.concatOrphans(specialOfferOwner.title.data.title) || specialOfferOwner.title.data.title;

  return (
    <section className='bg-white py-40 px-10 z-0 items-center grid lg:grid-cols-2 gap-y-10 lg:gap-x-6'>
      <div className='flex justify-center lg:justify-end'>
        <div className='relative z-0 w-full max-w-[760px] py-10 px-8 sm:px-12'>
          <div className={'bg-squares md:flex absolute w-3/4 max-w-[500px] h-full top-0 right-0 z-[-1]'}></div>
          <GatsbyImage className={'h-[280px] sm:h-[340px] md:h-[440px] rounded select-none'} alt={specialOfferOwner.image.alternativeText} image={specialOfferOwner.image.localFile.childImageSharp.gatsbyImageData} />
        </div>
      </div>
      <div className='min-h-[300px] sm:min-h-[400px] w-full lg:w-3/4 max-w-[800px] flex flex-col items-center lg:items-start'>
        <div className='font-head font-medium text-3xl md:text-4xl xl:text-[2.625rem] !leading-tight mb-6 text-center lg:text-left' dangerouslySetInnerHTML={{__html: title}}/>
        <div className='text-neutrals-20 text-lg mb-12 text-center lg:text-left' dangerouslySetInnerHTML={{__html: description}}/>

        <CustomLink isInternal to={specialOfferOwner.button.url} className={'mtr-btn-large mtr-btn-[secondary]'}>
          <span>{specialOfferOwner.button.label}</span>
        </CustomLink>
      </div>
    </section>
  )
}

export default SpecialOfferOwner;

import React, {useRef, useState} from "react"
import {graphql, useStaticQuery} from "gatsby"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import {useForm} from "react-hook-form";

import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import TextFormatter from "../../utils/TextFormatter";
import Modal, {ModalOptions, ModalRef, Position} from "../shared/modal/modal";
import FormError from "../shared/form-error/FormError";
import {FormMessageHelper} from "../../utils/FormMessage.helper";
import {MessageEnum} from "../../types/form-message.interface";
import CustomLink from "../shared/custom-link/CustomLink";

type DemoProps = {
  strapiHomepage: {
    demo: {
      title: string,
      description: string,
      inputPlaceholder: string,
      buttonLabel: string,
      image: GatsbyImageCustomType,
    }
  }
}

interface IFormState {
  email: string,
}

const modalOptions = {
  position: Position.middle,
  closeOnEsc: true,
  closeOnOverlay: false,
} as ModalOptions;

const Demo = () => {
  const ref = useRef<ModalRef>(null);
  const URL = `${process.env.MYTRACKO_API_URL}/form-message`;
  const {register, reset, handleSubmit, formState: {errors}} = useForm<IFormState>();
  const [requestIsSending, setRequestIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const onSubmit = handleSubmit((data) => {
    setRequestIsSending(true);
    setErrorMessage(null);

    const body = FormMessageHelper.getBodyRequest(MessageEnum.demo, {...data})

    fetch(URL, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(body)
    })
      .then((res) => {
        setRequestIsSending(false);
        if (res.ok) {
          ref.current?.openModal();
          return reset();
        }

        return res.json();
      })
      .then(data => {
        if (data) throw data.message;
      })
      .catch(e => {
        setErrorMessage(typeof e === 'string' ? e : 'Coś poszło nie tak. Spróbuj ponownie.')
        setRequestIsSending(false);
      })
  })
  const {strapiHomepage: { demo }} = useStaticQuery<DemoProps>(query);
  const description = TextFormatter.concatOrphans(demo.description) || demo.description;

  return (
    <section className={'bg-primary-60'}>
      <div className={'max-w-[1920px] ml-auto mr-auto flex overflow-hidden flex-col lg:flex-row sm:items-center justify-center sm:justify-baseline'}>
        <div className={'flex flex-col px-6 lg:ml-36 mt-16 items-center text-center sm:text-start sm:items-baseline lg:mt-0 sm:max-w-[570px]'}>
          <h2 className={'font-head font-medium text-white text-4xl xl:text-5xl mb-6'}>{demo.title}</h2>
          <p className={'text-lg text-white mb-6'} dangerouslySetInnerHTML={{__html: description}}/>
          <form onSubmit={onSubmit} className={'flex flex-1 items-center flex-col'}>
            <div className={'flex flex-1 items-center flex-col xs:flex-row gap-2.5'}>
              <div>
                <input
                  {...register('email',
                    {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: ""
                      }
                    })}
                  name='email'
                  id='email'
                  className={`pl-3.5 text-sm xs:w-52 md:w-64 rounded flex-grow h-[48px] focus:outline-none block ${errors.email ? 'outline outline-2 outline-error-40' : ''}`}
                  placeholder={demo.inputPlaceholder}
                />
              </div>
              <button type='submit' className={'self-start mtr-btn-large mtr-btn-[secondary] !w-full xs:!w-auto whitespace-nowrap !text-white'}>
                <span>{demo.buttonLabel}</span>
              </button>
            </div>
            <div className={'h-6 xs:mr-auto'}>
              {errors.email?.type === 'required' && <FormError message={'Nie podano adresu email'}/>}
              {errors.email?.type === 'pattern' && <FormError message={'Proszę podać poprawny email'}/>}
              {errorMessage && <FormError message={errorMessage}/>}
            </div>
          </form>
          <p className={`text-white text-xs lg:text-sm`}>
            *Korzystając z powyższej formy kontaktu potwierdzasz zapoznanie się i&nbsp;akceptację
            <CustomLink
              className={'underline ml-1 hover:text-neutrals-80 transition-colors'}
              isInternal to={'/regulaminy/polityka-prywatnosci'}
            >
              Polityki Prywatności
            </CustomLink>
          </p>
        </div>
        <GatsbyImage objectPosition={'top left'} className={'ml-auto mt-11 lg:mt-28 !h-[260px] lg:!h-[473px]'} alt={demo.image.alternativeText} image={demo.image.localFile.childImageSharp.gatsbyImageData} />
      </div>

      <Modal ref={ref} options={modalOptions}>
        <div
          className={'flex flex-col py-28 px-20 max-w-[520px] max-h-[546px] bg-white rounded text-center items-center'}>
          <StaticImage className={'mb-5'} loading={'eager'} placeholder={'blurred'} src={'../../assets/check_icon.png'} alt="" />
          <h2 className={'text-4xl text-neutrals-20 mb-2.5'}>Udało się!</h2>
          <p className={'text-lg text-neutrals-40 mb-5 px-2'}>Niedługo się z Tobą skontaktujemy.</p>
          <button type="button" disabled={requestIsSending} onClick={() => ref.current?.closeModal()} className={'mtr-btn-large mtr-btn-[secondary] w-fit'}>
            <span className={'mtr-icon'}>arrow_back</span>
            <span>POWRÓT</span>
          </button>
        </div>
      </Modal>
    </section>
  )
}

export default Demo

export const query = graphql`
  query {
    strapiHomepage {
      demo {
        title
        description
        inputPlaceholder
        buttonLabel
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FIXED, height: 473, quality: 100)
            }
          }
        }
      }
    }
  }
`

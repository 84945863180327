import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import TextFormatter from "../../../utils/TextFormatter";
import {ISpecialOfferSection} from "./special-offer.interface";
import CustomLink from "../../shared/custom-link/CustomLink";

interface ISpecialOfferProps {
  specialOffer: ISpecialOfferSection,
  className?: string,
  green?: boolean,
}

const SpecialOffer = ({ specialOffer, className, green }: ISpecialOfferProps) => {
  const description = TextFormatter.concatOrphans(specialOffer.description.data.description) || specialOffer.description.data.description;
  const title = TextFormatter.concatOrphans(specialOffer.title.data.title) || specialOffer.title.data.title;

  return (
    <section className={className || 'bg-white md:pt-20 md:pb-36 md:px-10 relative z-0 items-center flex justify-center'}>
      <div className={'bg-squares hidden md:flex absolute w-[300px] h-[706px] top-0 right-1/2 z-[-1] translate-x-1/2'}></div>
      <div className={'flex flex-col lg:flex-row justify-center z-10'}>
        <div className={`flex rounded-t lg:rounded-none lg:rounded-l lg:rounded-br md:mt-10 flex-col ${green ? `bg-primary-60` : 'bg-tertiary-40'} xs:h-[518px] md:h-[540px] px-5 py-12 sm:pt-20 sm:pb-24 md:px-12 gap-11 text-center justify-center items-center max-w-[670px]`}>
          <div className={'font-head text-white font-medium text-3xl md:text-4xl xl:text-[2.625rem] !leading-tight'} dangerouslySetInnerHTML={{__html: title}}/>
          <div className={'text-neutrals-80 text-lg'} dangerouslySetInnerHTML={{__html: description}}/>
          <CustomLink isInternal to={specialOffer.button.url} className={'mtr-btn-large mtr-btn-[secondary]'}>
            <span>{specialOffer.button.label}</span>
          </CustomLink>
        </div>
        <GatsbyImage className={'h-[440px] sm:h-[540px] lg:rounded-none rounded-b lg:rounded-r lg:rounded-tl'} alt={specialOffer.image.alternativeText} image={specialOffer.image.localFile.childImageSharp.gatsbyImageData} />
      </div>
    </section>
  )
}

export default SpecialOffer;

import React from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../types/gatsby-image.type";
import TextFormatter from "../../utils/TextFormatter";
import CustomLink from "../shared/custom-link/CustomLink";

export type DeviceProps = {
  title: string,
  partnerTitle: string,
  subtitle: string,
  description: string,
  properties: [
    {
      text: string,
    }
  ],
  buttons: {
    url: string,
    label: string,
  }[],
  images: GatsbyImageCustomType[]
}

const Device = ({device}: {device: DeviceProps}) => {
  const description = TextFormatter.concatOrphans(device.description) || device.description;
  const title = TextFormatter.concatOrphans(device.title) || device.title;

  return (
    <section className={'bg-tertiary-40 px-10 lg:px-36 py-24 relative overflow-hidden'}>
      {/* white bar at the bottom*/}
      <div className={'bg-white w-full h-[360px] absolute z-0 bottom-0 left-0'} />

      <div className={'flex flex-col items-center'}>

        <div className={'grid grid-cols-2 gap-x-16 mr-12 md:mt-16'}>
          <h2 className={'font-head text-4xl xl:text-5xl mb-10 lg:mb-28 text-white max-w-[500px] col-span-2 md:col-span-1'} dangerouslySetInnerHTML={{__html: title}}/>
          <p className={'text-lg xl:text-xl text-neutrals-90 mb-10 lg:mb-28 max-w-[500px] col-span-2 md:col-span-1'} dangerouslySetInnerHTML={{__html: description}}/>
          <h3 className={'text-primary-60 text-xl xl:text-2xl mb-10 col-span-2'}>{device.subtitle}</h3>
          <ul className={'list-disc ml-3.5 text-white columns-1 md:columns-2 gap-x-16 text-lg xl:text-xl col-span-2'}>
            {device.properties.map((property, index) => {
              return (
                <li className={'mb-2 lg:mb-7 last:mb-0 max-w-[400px] xl:max-w-[500px] break-inside-avoid-column'} key={index} dangerouslySetInnerHTML={{__html: TextFormatter.concatOrphans(property.text) || property.text}}/>
              )
            })}
          </ul>
        </div>

        <div className={'flex flex-col justify-center items-center xs:flex-row gap-3 mb-12 md:mb-24 mt-10 md:mt-16'}>
          <CustomLink isInternal className={'mtr-btn-large mtr-btn-[secondary] uppercase'} to={device.buttons[0].url}>
            <span>{device.buttons[0].label}</span>
          </CustomLink>
          <CustomLink isInternal className={'mtr-btn-large mtr-btn-outline-dark-[secondary] uppercase'} to={device.buttons[1].url}>
            <span>{device.buttons[1].label}</span>
          </CustomLink>
        </div>

        <div className={'flex flex-row gap-5 justify-center'}>
          <div className={'flex flex-col gap-5 items-center'}>
            <div className={'border-0 md:border-2 border-white rounded flex flex-col py-5 px-6 lg:px-24 text-center w-full'}>
              <p className={'uppercase mb-5 text-lg xs:truncate md:text-sm lg:text-base text-white'}>{device.partnerTitle}</p>
              <div className={'flex gap-5 lg:gap-12 items-center justify-center'}>
                <StaticImage placeholder={'blurred'} className={'w-[49px] h-[49px] md:w-[28px] md:h-[28px] lg:w-[49px] lg:h-[49px]'} src={'../../assets/orange_logo.png'} alt="Logo firmy Orange - partner MYTRACKO"/>
                <StaticImage placeholder={'blurred'} className={'w-[150px] h-[29px] md:w-[131px] md:h-[21px] lg:w-[150px] lg:h-[29px]'} src={'../../assets/teltonika_logo.png'} alt="Logo firmy Teltonika - partner MYTRACKO"/>
              </div>
            </div>
            <div className={'flex justify-center items-center rounded overflow-hidden bg-neutrals-90 z-10'}>
              <GatsbyImage className={'transition hover:scale-105'} alt={device.images[0].alternativeText} image={device.images[0].localFile.childImageSharp.gatsbyImageData}/>
            </div>
          </div>

          <div className={'hidden md:grid grid-cols-[minmax(193px,_243px),_minmax(131px,_171px)] lg:grid-cols-[270px,_190px] xl:grid-cols-[305px,_215px] gap-5 relative'}>
            <div className={'bg-squares absolute top-[-3.5rem] h-[618px] w-[376px] left-20 translate-x-1/2 z-0'}></div>
            <div className={'block rounded overflow-hidden col-span-2'}>
              <GatsbyImage className={'transition hover:scale-105 h-full'} alt={device.images[1].alternativeText} image={device.images[1].localFile.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={'block w-full h-fit rounded overflow-hidden'}>
              <GatsbyImage className={'transition hover:scale-105'} alt={device.images[2].alternativeText} image={device.images[2].localFile.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={'block w-full h-fit rounded overflow-hidden'}>
              <GatsbyImage className={'transition hover:scale-105'} alt={device.images[3].alternativeText} image={device.images[3].localFile.childImageSharp.gatsbyImageData}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Device;

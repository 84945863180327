import React from "react";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

import './hero.css'
import Scroller from "../../../utils/Scroller";
import {SectionIdEnum} from "../../../types/section-id.enum";
import TextFormatter from "../../../utils/TextFormatter";
import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import CustomLink from "../../shared/custom-link/CustomLink";

export type HeroProps = {
  title: [{ text: string }],
  description: string,
  button: string,
  background: GatsbyImageCustomType,
  flyer: GatsbyImageCustomType,
}

const Hero = ({hero}: {hero: HeroProps}) => {
  const scrollToFeaturesSection = () => Scroller.scrollTo(SectionIdEnum.FEATURES, window.innerWidth < 1280 ? 95 : 100);

  return (
    <section className="items-center flex justify-center lg:bg-tertiary-40 h-fit">
      <div className={'relative items-center max-w-[1920px] ml-auto mr-auto hero-text-container z-10 flex w-full p-5 lg:p-0 text-white'}>
        <div className={'w-full sm:p-10 lg:w-[49rem] xl:w-[54rem] flex flex-col lg:pl-10 xl:pl-24 justify-center'}>
          <h1>
            {hero.title.map((t, i) => (<span key={i} className={'font-head block title relative pl-6 mb-3 text-4xl lg:text-6xl'}>{t.text}</span>))}
          </h1>
          <p className={'text-base z-10 xl:text-lg mt-6 xl:mt-10 mb-9'} dangerouslySetInnerHTML={{__html: TextFormatter.concatOrphans(hero.description) || hero.description}} />
          <div className={'flex flex-col sm:flex-row flex-wrap h-full sm:h-auto justify-between gap-y-8 gap-x-4 xl:gap-8 items-center'}>
            <div className={'flex gap-4 xl:gap-8 items-center'}>
              <CustomLink isInternal to={'/monitoring/e-toll/'} className="mtr-btn xl:mtr-btn-large mtr-btn-[secondary]">
                <span>{hero.button}</span>
                <span className="mtr-icon mtr-icon-right">arrow_forward</span>
              </CustomLink>
              <button type="button" onClick={scrollToFeaturesSection} className="mtr-btn !text-white xl:mtr-btn-large mtr-btn-outline-dark-[secondary]">
                <span>Zobacz więcej</span>
              </button>
            </div>

            <div className={'flex h-auto mt-auto items-center gap-4 sm:mt-0 xl:gap-8'}>
              <StaticImage loading={'eager'} placeholder={'blurred'} className={'w-[28px] h-[28px] lg:w-[49px] lg:h-[49px]'} src={'../../../assets/orange_logo.png'} alt="Orange, partner techniczny MYTRACKO"/>
              <StaticImage loading={'eager'} placeholder={'blurred'} className={'w-[131px] h-[21px] lg:w-[150px] lg:h-[29px]'} src={'../../../assets/teltonika_logo.png'} alt="Teltonika, partner techniczny MYTRACKO"/>
            </div>
          </div>
        </div>
        <div className={'ml-auto mt-auto self-end relative hidden lg:flex pointer-events-none select-none'}>
          <div className={'bg-squares absolute translate-y-[-50%] mr-10 right-0 w-[300px] h-[618px] top-1/2'}></div>
          <div className={'w-48 h-48 bg-secondary-60 rounded-tr-[50px] absolute translate-y-[-50%] mr-10 right-4 top-36'}/>
          <GatsbyImage loading={'eager'} className={'pr-12'} alt={hero.flyer.alternativeText} image={hero.flyer.localFile.childImageSharp.gatsbyImageData}/>
        </div>
        <div className={'max-w-[1920px] gradient w-full lg:w-4/5 h-full absolute top-0 left-0 lg:mt-0 pointer-events-none select-none z-[-1]'}>
          <GatsbyImage loading={'eager'} alt={hero.background.alternativeText} className={'w-full h-full'} image={hero.background.localFile.childImageSharp.gatsbyImageData}/>
        </div>
      </div>
    </section>
  )
}

export default Hero;
